/* You can add global styles to this file, and also import other style files */
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.mt-icon {
  margin-top: 140px !important;
}

.white-box {
  background-color: #fff;
}

.modal.show {
  display: block;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0) !important;
}

.truck-backdrop {
  background-color: white !important;
}

/*gb-modal-window > div:first-child {
  max-width: 0px;
}*/

.modal-dialog.modal-dialog-centered {
  max-width: 440px;
}

.modal-md {
  max-width: 700px;
}

.modal:not(.exampleModalCenter) .modal-panel:not(.closing) {
  width: 0;
  -webkit-animation: slideOpen 0.5s ease-in-out forwards;
  animation: slideOpen 0.5s ease-in-out forwards;
}

@-webkit-keyframes slideOpen {
  100% {
    width: 100%;
  }
}

@keyframes slideOpen {
  100% {
    width: 100%;
  }
}

.modal-dialog:not(.modal-panel):not(.closing) {
  margin: -1000px auto 1.75rem auto;
  -webkit-animation: slideDown 0.3s ease-out forwards;
  animation: slideDown 0.3s ease-out forwards;
}

@-webkit-keyframes slideDown {
  100% {
    margin: 1.75rem auto;
  }
}

@keyframes slideDown {
  100% {
    margin: 1.75rem auto;
  }
}

.modal-dialog.closing:not(.modal-panel) {
  margin: 1.75rem auto;
  -webkit-animation: slideUp 0.3s ease-out forwards;
  animation: slideUp 0.3s ease-out forwards;
}

@-webkit-keyframes slideUp {
  100% {
    margin: -1000px auto 1.75rem auto;
  }
}

@keyframes slideUp {
  100% {
    margin: -1000px auto 1.75rem auto;
  }
}

.modal:not(.exampleModalCenter) .modal-panel.closing {
  width: 100%;
  -webkit-animation: slideClosed 0.3s ease-out forwards;
  animation: slideClosed 0.3s ease-out forwards;
}

@-webkit-keyframes slideClosed {
  100% {
    width: 0;
  }
}

@keyframes slideClosed {
  100% {
    width: 0;
  }
}


.modal-header {
  min-height: auto !important;
  position: relative;
}

@media screen and (min-width: 576px) {
  .modal-sm .modal-header,
  .modal-sm .modal-body,
  .modal-sm .modal-footer {
    width: 440px !important;
  }

  .modal-md .modal-header,
  .modal-md .modal-body,
  .modal-md .modal-footer {
    min-width: 440px !important;
  }

  .show .modal-medium .modal-header,
  .show .modal-medium .modal-body,
  .show .modal-medium .modal-footer {
    width: 850px !important;
  }

  .modal-lg .modal-header,
  .modal-lg .modal-body,
  .modal-lg .modal-footer {
    width: calc(100vw - 100px);
  }
}

@media screen and (max-width: 575px) {
  .modal-sm .modal-header,
  .modal-sm .modal-body,
  .modal-sm .modal-footer {
    width: 100vw !important;
  }
}

.modal-backdrop.fade {
  transition: none;
  opacity: 0;
  -webkit-animation: backdropFadeIn 0.15s linear forwards;
  animation: backdropFadeIn 0.15s linear forwards;
}

@-webkit-keyframes backdropFadeIn {
  100% {
    opacity: 0.8;
  }
}

@keyframes backdropFadeIn {
  100% {
    opacity: 0.8;
  }
}

.modal-backdrop.fade.closing {
  transition: none;
  opacity: 0.8;
  -webkit-animation: backdropFadeOut 0.3s linear forwards;
  animation: backdropFadeOut 0.3s linear forwards;
}

@-webkit-keyframes backdropFadeOut {
  100% {
    opacity: 0;
  }
}

@keyframes backdropFadeOut {
  100% {
    opacity: 0;
  }
}

.modal-medium {
  max-width: 850px !important;
}


app-jjk-work-month .ngb-dp-content.ngb-dp-months {
  height: 7px;
}

ngb-datepicker.dropdown-menu.ngb-dp-body {
  z-index: 999999999;
}

app-jjk-date-picker.invalid input,
app-jjk-time-picker.invalid input {
    border: 2px solid #a94442;
}

.kicker {
  margin-top: 4px;
  font-weight: bold;
  color: #6698b3;
  font-size: 0.75rem;
  white-space: nowrap;
}
